import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Categories from "./Categories/Categories";
import Products from "./Products/Products";
import AddProduct from "./Products/AddProduct";
import Dashboard from "./Dashboard/Dashboard";
import { useSelector } from "react-redux";
import BOMList from "./BOMList";
import SessionList from "./SessionList";

const AppRouter = () => {
  const userData = useSelector((state) => state.user);
  const groupId = userData?.user?.group?.uuid;
  let defaultRoute = `/dashboard/${groupId}`;

  return (
    <Routes>
      <Route path="/dashboard/:groupId" element={<Dashboard />} />
      <Route path="/bom-list/:groupId" element={<BOMList />} />
      <Route path="/sessions-list/:groupId" element={<SessionList />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/categories/:categoryId" element={<Categories />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:categoryId" element={<Products />} />
      <Route path="/products/add" element={<AddProduct edit={true} />} />
      <Route path="/products/edit/:productId" element={<AddProduct edit={true} />} />
      <Route path="/products/view/:productId" element={<AddProduct edit={false} />} />
      <Route path="*" element={<Navigate to={defaultRoute} />} />
    </Routes>
  );
};

export default AppRouter;
