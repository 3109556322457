import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CSVUploadDropZone from "./CSVUploadDropZone";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { CloseIcon } from "../../Assets/Icons";
import apiClient from "../../apiClient";
import { useSelector } from "react-redux";
import { UPLOAD_BULK_PRODUCTS } from "../../apiClient/apiEndpoints";
import { saveFile } from "../../utils/common";

const csvData = {
  Name: "Product name",
  CategoryId: "bd1474ba-0daa-4179-8815-4b839c29c42a",
  LNCode: "LN0001",
  HSNCode: "HSN0001",
  Description: "This is product description",
  Features: "This is product feature",
  Specification: "This is product specification",
  Price: 100,
  Discount: 5,
  Height: 10,
  Width: 25,
  Depth: 20,
  FrontView: "",
  TopView: "",
  Status: 1,
};
const csvHeaders = [
  "Name",
  "CategoryId",
  "LNCode",
  "HSNCode",
  "Description",
  "Features",
  "Specification",
  "Price (?)",
  "Discount",
  "Height",
  "Width",
  "Depth",
  "FrontView",
  "TopView",
  "Status",
];
const csvContent = [
  csvHeaders.join(","),
  [
    csvData.Name,
    csvData.CategoryId,
    csvData.LNCode,
    csvData.HSNCode,
    csvData.Description,
    csvData.Features,
    csvData.Specification,
    csvData.Price,
    csvData.Discount,
    csvData.Height,
    csvData.Width,
    csvData.Depth,
    csvData.FrontView,
    csvData.TopView,
    csvData.Status,
  ].join(","),
].join("\n");

const ProductCSVUpload = ({ showCSV, hideCsvModal, loading, setLoading }) => {
  const [productCsvList, setProductCsvList] = useState([]);
  const [csvErrorList, setCsvErrorList] = useState({});
  const [error, setError] = useState(null);
  const [modalSize, setModalSize] = useState("modal-md");
  const configData = useSelector((state) => state.config);
  const API_URL = configData?.config?.API_URL;

  const handleDownloadCSV = () => {
    saveFile(csvContent, "ProductSampleCSV.csv");
  };

  // Mock CSV validation function
  const validateCSV = (data) => {
    const isValid = data.every((record, index) => {
      const { name, categoryId, price, status } = record;

      // Validate required fields
      if (!name) return false;
      if (!categoryId) return false;

      if (!price && Number(price) !== 0) return false;

      if (status !== 0 && status !== 1) {
        return false;
      }

      return true;
    });

    if (!isValid) {
      setError("CSV data validation failed.");
    }
    return isValid;
  };

  // Mock CSV file processing (replace this with actual file reading logic if needed)
  const handleProductFileUpload = (data) => {
    const csvObject = [];
    const headers = [
      "Name",
      "CategoryId",
      "LNCode",
      "HSNCode",
      "Description",
      "Features",
      "Specification",
      "Price (?)",
      "Discount",
      "Height",
      "Width",
      "Depth",
      "FrontView",
      "TopView",
      "Status",
    ];
    const headersIncluded = headers.every((header) => data?.fields.includes(header));
    if (headersIncluded) {
      data?.data.forEach((e, i) => {
        const _name = e.Name ? String(e.Name).trim().replace(/\s+/g, " ") || null : null;
        const _categoryId = e.CategoryId ? String(e.CategoryId).trim().replace(/\s+/g, " ") || null : null;
        const _lnCode = e.LNCode ? String(e.LNCode).trim().replace(/\s+/g, " ") || null : null;
        const _hsnCode = e.HSNCode ? String(e.HSNCode).trim().replace(/\s+/g, " ") || null : null;
        const _description = e.Description ? String(e.Description).trim().replace(/\s+/g, " ") || null : null;
        const _features = e.Features ? String(e.Features).trim().replace(/\s+/g, " ") || null : null;
        const _specification = e.Specification ? String(e.Specification).trim().replace(/\s+/g, " ") || null : null;
        const _price = e["Price (?)"] ? String(e["Price (?)"]).trim().replace(/\s+/g, " ") || null : null;
        const _discount = e.Discount ? String(e.Discount).trim().replace(/\s+/g, " ") || null : null;
        const _height = e.Height ? String(e.Height).trim().replace(/\s+/g, " ") || null : null;
        const _width = e.Width ? String(e.Width).trim().replace(/\s+/g, " ") || null : null;
        const _depth = e.Depth ? String(e.Depth).trim().replace(/\s+/g, " ") || null : null;
        const _frontView = e.FrontView ? String(e.FrontView).trim().replace(/\s+/g, " ") || null : null;
        const _topView = e.TopView ? String(e.TopView).trim().replace(/\s+/g, " ") || null : null;
        const _status = e.Status;

        if (
          _name ||
          _categoryId ||
          _lnCode ||
          _hsnCode ||
          _description ||
          _features ||
          _specification ||
          _price ||
          _discount ||
          _height ||
          _width ||
          _depth ||
          _frontView ||
          _topView ||
          _status === 0 ||
          _status === 1
        ) {
          const object = {
            id: i + 1,
            name: _name.replace(";", ","),
            categoryId: _categoryId,
            lnCode: _lnCode?.replace(";", ","),
            hsnCode: _hsnCode?.replace(";", ","),
            description: _description,
            features: _features,
            specification: _specification,
            price: _price,
            discount: _discount,
            height: _height,
            width: _width,
            depth: _depth,
            frontView: _frontView,
            topView: _topView,
            status: _status,
          };
          csvObject.push(object);
        }
      });

      if (validateCSV(csvObject)) {
        setProductCsvList(csvObject);
        setModalSize("modal-xl");
      } else {
        setError("CSV validation failed.");
        setTimeout(() => setError(null), 4000);
      }
    } else {
      setError("CSV validation failed.");
      setTimeout(() => setError(null), 4000);
    }
  };

  const deleteProduct = (id) => {
    const updatedList = productCsvList.filter((category) => category.id !== id);
    setProductCsvList(updatedList);
    if (updatedList.length === 0) setModalSize("modal-md");
  };

  const handleSubmitCategoryCSV = async () => {
    setLoading((prev) => ({ ...prev, upload: true }));
    const payload = {
      data: productCsvList,
    };
    setCsvErrorList({});
    const response = await apiClient.post(`${API_URL}${UPLOAD_BULK_PRODUCTS}`, payload);
    setProductCsvList([]);
    setCsvErrorList({ ...response?.data });
    setLoading((prev) => ({ ...prev, upload: false }));
  };

  const handleProductModalClose = () => {
    setError(null);
    setProductCsvList([]);
    setCsvErrorList({});
    setModalSize("modal-md");
    hideCsvModal();
  };

  return (
    <Modal centered className={`csvuploaddrr ${modalSize}`} show={showCSV} onHide={handleProductModalClose}>
      <Modal.Header className="modalheader text-center">
        Product Upload CSV
        <span onClick={handleProductModalClose}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="modalcont mt-3">
          <div className="row">
            <div className="col-12">
              {productCsvList.length > 0 ? (
                <div className="csvfiledatalist">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>LN Code</th>
                        <th>HSN Code</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productCsvList.map((product, index) => (
                        <tr key={product.id}>
                          <td>{index + 1}</td>
                          <td>
                            <span className="d-flex align-items-center">
                              {product?.frontView ? (
                                <img className="avatartc" src={product.frontView} alt="" />
                              ) : (
                                <div className="avcharactr">
                                  {product.name
                                    ?.split(" ")
                                    .slice(0, 2)
                                    .map((word) => word[0].toUpperCase())
                                    .join("")}
                                </div>
                              )}
                              {product.name}
                            </span>
                          </td>
                          <td>{product.description}</td>
                          <td>{product.lnCode}</td>
                          <td>{product.hsnCode}</td>
                          <td>{product.status === 1 ? "Active" : "Inactive"}</td>
                          <td>
                            <span className="cancelcsv" onClick={() => deleteProduct(product.id)}>
                              <HighlightOffOutlinedIcon />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="csvuploadefs">
                  <CSVUploadDropZone
                    onFileUpload={handleProductFileUpload}
                    text="Drag & drop or choose file to upload CSV"
                    accept=".csv"
                  />
                  <h6 className="mt-3 mb-0">Required Fields*</h6>
                  <p className="text-xs">
                    {`Name, CategoryId, HSN Code, LN Code, MRP, Status (Status should be only 0 or 1)`} <br />
                  </p>
                  {error && <p className="errormessage">{error}</p>}
                  <div className="d-flex justify-content-between align-item-center mt-3">
                    <p className="mb-0">
                      <a onClick={handleDownloadCSV} className="download-text" style={{ cursor: "pointer" }}>
                        <span>Download Product CSV Template</span>
                      </a>
                    </p>
                    <button onClick={handleProductModalClose} className="createbtn cancelbtn">
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>

            {productCsvList.length > 0 && (
              <div className="modal-footer-btn mt-3">
                <button className="createbtn cancelbtn" onClick={handleProductModalClose}>
                  Cancel
                </button>
                {loading.upload ? (
                  <button className="loading save">
                    <svg viewBox="25 25 50 50">
                      <circle r="20" cy="50" cx="50">
                        {""}
                      </circle>
                    </svg>
                  </button>
                ) : (
                  <button className="createbtn" onClick={handleSubmitCategoryCSV}>
                    Upload
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductCSVUpload;
