import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LottiePlayer from "lottie-react";
import NoDataFoundAnim from "../../Assets/Animation/nodatafound.json";
import {
  BackIcon,
  CancelIcon,
  CloseIcon,
  EditIcon,
  EXLDownloadIcon,
  SearchesCIcon,
  SpinnerIcon,
} from "../../Assets/Icons";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "../../apiClient";
import { useDispatch, useSelector } from "react-redux";
import {
  EDIT_PRODUCT,
  FETCH_CATEGORY_FILTER_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_REPORT,
} from "../../apiClient/apiEndpoints";
import IOSSwitch from "../Common/Switch";
import Filter from "../Common/Filter";
import { generateExcel, makefilterQueryParams, PRODUCT_REPORT_NAME, getNameInitials } from "../../utils/common";
import ProductCSVUpload from "../Common/ProductCSVUpload";
import { saveProductPage } from "../../store/slice/paginationSlice";

const defaultFilterOptions = [
  {
    name: "Status",
    tags: [
      { name: "Active", key: "active", value: true },
      { name: "Inactive", key: "active", value: false },
    ],
  },
];

const PRODUCT_TYPE = {
  SINGLE: "SINGLE",
  CONFIGURABLE: "CONFIGURABLE",
};

const Products = () => {
  const configData = useSelector((state) => state.config);
  const pageInfo = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState({});
  const [pageNumber, setPageNumber] = useState(pageInfo.product);
  const [slug, setSlug] = useState("");
  const [activeStatus, setActiveStatus] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);
  const [csvUpload, setCsvUpload] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState({})
  const [reportLoading, setReportLoading] = useState({
    download: false,
    upload: false,
  });

  const API_URL = configData?.config?.API_URL;

  const fetchProductList = async () => {
    let queryParams = {};
    queryParams.pageSize = configData.config?.DEFAULT_PAGE_SIZE || 10;
    queryParams.pageNumber = pageNumber;
    if (slug) {
      queryParams.query = slug;
    }
    queryParams = { ...queryParams, ...makefilterQueryParams(selectedCheckboxes) };
    const res = await apiClient.get(`${API_URL}${FETCH_PRODUCT_LIST}`, { params: queryParams });
    const combinedProductList = res.records?.map((item) => {
      if (item.type === PRODUCT_TYPE.CONFIGURABLE) {
        item.price = item.children?.reduce((init, child) => init + Number(child.price), 0);
        item.lnCode = item.children?.map((child) => child.lnCode).join(", ");
        return item;
      } else {
        return item;
      }
    });
    setProductList({ ...res, records: combinedProductList });
    setLoading(false);
    const activityStatusObj = {};
    res.records?.map((item) => {
      activityStatusObj[item.uuid] = item.active;
    });
    setActiveStatus(activityStatusObj);
  };

  const fetchProductsInCategory = async () => {
    let queryParams = {};
    queryParams.pageSize = configData.config?.DEFAULT_PAGE_SIZE || 10;
    queryParams.pageNumber = pageNumber;
    if (slug) {
      queryParams.query = slug;
    }
    queryParams = { ...queryParams, category: [categoryId], ...makefilterQueryParams(selectedCheckboxes) };
    const res = await apiClient.get(`${API_URL}${FETCH_PRODUCT_LIST}`, { params: queryParams });
    const combinedProductList = res.records?.map((item) => {
      if (item.type === PRODUCT_TYPE.CONFIGURABLE) {
        item.price = item.children?.reduce((init, child) => init + Number(child.price), 0);
        item.lnCode = item.children?.map((child) => child.lnCode).join(", ");
        return item;
      } else {
        return item;
      }
    });
    setProductList({ ...res, records: combinedProductList });
    setLoading(false);
    const activityStatusObj = {};
    res.records?.map((item) => {
      activityStatusObj[item.uuid] = item.active;
    });
    setActiveStatus(activityStatusObj);
  };

  const fetchCategoryFilterList = async () => {
    const res = await apiClient.get(`${API_URL}${FETCH_CATEGORY_FILTER_LIST}`);
    if (!categoryId) {
      setFilterOptions([
        ...defaultFilterOptions,
        { name: "Category", tags: res.map((item) => ({ name: item.name, key: "category", value: item.uuid })) },
      ]);
    } else {
      setBreadCrumb(res.find(item => item.uuid === categoryId))
    }
  };

  const toggleChangeHandler = async (uuid, toggleStatus) => {
    const productToEdit = productList.records.find((value) => value.uuid === uuid);
    const body = {
      active: toggleStatus,
      topView: productToEdit.data?.productViews?.topView,
      frontView: productToEdit.data?.productViews?.frontView,
      ...(productToEdit.type === PRODUCT_TYPE.CONFIGURABLE && {
        assignedProducts: productToEdit.children.map((item) => item.uuid),
      }),
    };
    const stringifiedBody = JSON.stringify(body);
    const formData = new FormData();
    formData.append("data", stringifiedBody);
    const res = await apiClient.put(`${API_URL}${EDIT_PRODUCT}/${uuid}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res) {
      fetchProductList();
    }
  };

  const openCsvModal = () => {
    setCsvUpload(true);
  };

  const hideCsvModal = () => {
    setCsvUpload(false);
  };

  const generateProductReport = async () => {
    setReportLoading((prev) => ({ ...prev, download: true }));
    const res = await apiClient.get(`${API_URL}${FETCH_PRODUCT_REPORT}`);
    const reportData = [];
    res.forEach((item, idx) => {
      if (item.type === PRODUCT_TYPE.SINGLE) {
        reportData.push({
          "S.No": idx + 1,
          // In some data columns there is a comma(","). ";" is the identifier for the comma
          // While  reading the csv replace the ";" with ","
          Name: item.name?.includes(",")
            ? "`" + (item.name.replace(",", ";") || "") + "`"
            : "`" + (item.name || "") + "`",
          CategoryId: item.productCategory?.uuid || "",
          LNCode: "`" + ((item.lnCode?.includes(",") ? item.lnCode.split(",").join(";") : item?.lnCode) || "") + "`",
          HSNCode: item.hsnCode
            ? "`" + ((item.hsnCode?.includes(",") ? item.hsnCode.split(",").join(";") : item?.hsnCode) || "") + "`"
            : "",
          Description: item?.data?.description || "",
          Features: item?.data?.features || "",
          Specification: item?.data?.specification || "",
          "Price (₹)": item.price || "",
          Discount: item?.data?.discount || "",
          Height: item?.data?.measurements?.height || "",
          Width: item?.data?.measurements?.width || "",
          Depth: item?.data?.measurements?.depth || "",
          FrontView: item?.data?.productViews?.frontView || "",
          TopView: item?.data?.productViews?.topView || "",
          Status: item?.active ? 1 : 0,
        });
      }
    });
    generateExcel(PRODUCT_REPORT_NAME, reportData);
    setReportLoading((prev) => ({ ...prev, download: false }));
  };

  useEffect(() => {
    if (categoryId) {
      fetchProductsInCategory();
    } else {
      fetchProductList();
    }
  }, [slug, selectedCheckboxes, pageNumber]);
  useEffect(() => {
    fetchCategoryFilterList();
  }, []);

  const handleFilterClear = (value) => {
    const filteredList = selectedCheckboxes.filter((filter) => filter.value !== value);
    setSelectedCheckboxes(filteredList);
  };

  const productViewClickHandler = (uuid) => {
    navigate(`/products/view/${uuid}`);
  };

  const productEditClickHandler = (uuid) => {
    navigate(`/products/edit/${uuid}`);
  };

  const changePage = (value) => {
    dispatch(saveProductPage(value));
    setPageNumber(value);
  };

  return (
    <div className="product-main">
      <div className="page-tile pb-2">
        <h5>Products </h5>
        <div className="searchnadd">
          <form className=" app-search app-search1 d-flex justify-content-start ">
            <div className="position-relative w-100">
              <SearchesCIcon />
              {slug && (
                <span
                  className="searchiconcancel"
                  onClick={() => {
                    setSlug("");
                  }}
                >
                  <CancelIcon />
                </span>
              )}
              <input
                type="text"
                name="searchName"
                className="form-control"
                placeholder="Search Products"
                autoComplete="off"
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
              />
            </div>
          </form>
          <Filter
            selectedCheckboxes={selectedCheckboxes}
            setSelectedCheckboxes={setSelectedCheckboxes}
            filterOptions={filterOptions}
          />
          <button className="classic-btn" onClick={() => navigate(`/products/add`)}>
            Add Product
          </button>
          <div className="csvuploaddownload">
            <button type="button" onClick={openCsvModal} className="uloadoanload">
              <FileUploadOutlinedIcon />
              <span>CSV</span>
            </button>
          </div>
          <button
            className="DefaultRedBtn download px-2"
            title="Download Excel"
            onClick={generateProductReport}
            disabled={reportLoading.download}
          >
            {reportLoading.download ? <SpinnerIcon /> : <EXLDownloadIcon />}
          </button>
        </div>
      </div>
      {categoryId && (
        <div className="breadcrumb">
          <button
            className="backbtn cursor-pointer ps-2"
            title="Back"
            onClick={() => navigate(`/categories/${breadCrumb?.parentCategory?.uuid}`)}
          >
            <BackIcon /> Back
          </button>
          <p className="active ms-2"> {breadCrumb?.name} Product List </p>
        </div>
      )}
      {selectedCheckboxes.length > 0 && (
        <div className="filerlist mt-3">
          <span>Filters:</span>
          {selectedCheckboxes.map((f) => {
            return (
              <span className="filtertag" key={f.value}>
                {f.name}
                <span onClick={() => handleFilterClear(f.value)}>
                  <CloseIcon />
                </span>
              </span>
            );
          })}
          <span className="filtertag clear" onClick={() => setSelectedCheckboxes([])} style={{ cursor: "pointer" }}>
            Clear all
          </span>
        </div>
      )}

      {loading ? (
        <>
          {
            <div className="tablenpage ">
              <div className="table-responsive cate-table p-0 ">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">S.No.</th>
                      <th className="text-center">Product Image</th>
                      <th>Product Name</th>
                      <th>Product Type</th>
                      <th>Category</th>
                      <th className="text-center">MRP</th>
                      <th className="text-center">LN Code</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {[...Array(4)].map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="table-text">
                            <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text">
                            <Skeleton animation="wave" variant="text" height="6.8rem" width="7.5rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text justify-content-start">
                            <Skeleton animation="wave" variant="text" height="2rem" width="12rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text justify-content-start">
                            <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text justify-content-start">
                            <Skeleton animation="wave" variant="text" height="2rem" width="12rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text">
                            <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text">
                            <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text">
                            <Skeleton animation="wave" variant="text" height="2rem" width="4rem" />
                          </div>
                        </td>
                        <td>
                          <div className="table-text">
                            <Skeleton animation="wave" variant="text" height="2rem" width="4rem" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          }
        </>
      ) : (
        <div className="tablenpage">
          <div className="table-responsive cate-table p-0 ">
            {productList.records?.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">S.No.</th>
                    <th className="text-center">Product Image</th>
                    <th>Product Name</th>
                    <th>Product Type</th>
                    <th>Category</th>
                    <th className="text-center">Price</th>
                    <th className="text-center">LN Code</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productList.records?.map((item, index) => (
                    <tr key={item.uuid}>
                      <td>
                        <div className="table-text">{index + productList.pagination.firstRecord}</div>
                      </td>
                      <td className="text-center">
                        {item.data?.productViews?.frontView ? (
                          <img src={item.data.productViews?.frontView} />
                        ) : (
                          <span className="img-ph">{getNameInitials(item.name)}</span>
                        )}
                      </td>
                      <td>
                        <div
                          className="table-text justify-content-start link-here"
                          onClick={() => productEditClickHandler(item.uuid)}
                        >
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start ">{item.type}</div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start ">{item.productCategory.name}</div>
                      </td>
                      <td>
                        <div className="table-text"> &#8377; {item.price || "NA"}</div>
                      </td>
                      <td>
                        <div className="table-text">{item.lnCode || "NA"}</div>
                      </td>
                      <td>
                        <div className="table-text">
                          <FormControlLabel
                            className="me-0"
                            name="active"
                            checked={activeStatus[item.uuid]}
                            onChange={(e) => {
                              setActiveStatus((prev) => ({ ...prev, [item.uuid]: e.target.checked }));
                              toggleChangeHandler(item.uuid, e.target.checked);
                            }}
                            control={<IOSSwitch sx={{ m: 1 }} />}
                          />
                          <span className={item.active ? "active-label" : "inactive-label"}>
                            {item.active ? "Active" : "Inactive"}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="table-text action-btn">
                          <span onClick={() => productEditClickHandler(item.uuid)}>
                            <EditIcon />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">S.No.</th>
                      <th>Product Name</th>
                      <th>Category</th>
                      <th className="text-center">Price</th>
                      <th className="text-center">LN Code</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                </table>
                <div className="no-data-found" style={{ borderRadius: "0" }}>
                  <LottiePlayer
                    animationData={NoDataFoundAnim}
                    style={{ height: 150, marginBottom: 0, border: "none" }}
                  />
                  <p>{slug ? "No categories found matching your search." : "No categories yet"}</p>
                  <button className="classic-btn" onClick={() => navigate("/products/add")}>
                    Add Product
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="pagination">
            <div className="current-stat">
              {productList.pagination?.firstRecord} - {productList.pagination?.lastRecord} of{" "}
              {productList.pagination?.totalRecords}
            </div>
            <div className="pages">
              <Pagination
                count={productList?.pagination?.totalPage}
                defaultPage={1}
                page={pageNumber}
                siblingCount={2}
                onChange={(e, pageNumber) => changePage(pageNumber)}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#333",
                    backgroundColor: "#f0f0f0",
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#ca373c",
                  },
                  "& .MuiPaginationItem-root:hover": {
                    backgroundColor: "#dcdcdc",
                  },
                }}
                boundaryCount={2}
              />
            </div>
          </div>
        </div>
      )}
      <ProductCSVUpload
        showCSV={csvUpload}
        hideCsvModal={hideCsvModal}
        loading={reportLoading}
        setLoading={setReportLoading}
      />
    </div>
  );
};

export default Products;
