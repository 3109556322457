import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CSVUploadDropZone from "./CSVUploadDropZone";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { CloseIcon } from "../../Assets/Icons";
import apiClient from "../../apiClient";
import { useSelector } from "react-redux";
import { UPLOAD_BULK_CATEGORY } from "../../apiClient/apiEndpoints";
import { saveFile } from "../../utils/common";

const csvData = {
  Name: "abc",
  Image: "",
  Description: "This is category description",
  Uuid: "e68da1c0-7476-434e-a3a9-6f6adb353127",
  ParentCategory: "479192d3-a745-4d4f-a419-ce817355408c",
  Status: 1,
};
const csvHeaders = ["Name", "Image", "Description", "Uuid", "ParentCategory", "Status"];
const csvContent = [
  csvHeaders.join(","),
  [csvData.Name, csvData.Image, csvData.Description, csvData.Uuid, csvData.ParentCategory, csvData.Status].join(","),
].join("\n");

export const CateCSVUpload = ({ showCSV, hideCsvModal, loading, setLoading }) => {
  const [categoryCsvList, setCategoryCsvList] = useState([]);
  const [csvErrorList, setCsvErrorList] = useState({});
  const [error, setError] = useState(null);
  const [modalSize, setModalSize] = useState("modal-md");
  const configData = useSelector((state) => state.config);
  const API_URL = configData?.config?.API_URL;

  const handleDownloadCSV = () => {
    saveFile(csvContent, "CategorySampleCSV.csv");
  };

  // Mock CSV validation function
  const validateCSV = (data) => {
    const isValid = data.every((record, index) => {
      const { name, uuid, status } = record;

      // Validate required fields
      if (!name) return false;

      // Ensure unique uuid
      if (uuid && data.slice(0, index).some((prevRecord) => prevRecord.uuid === uuid)) {
        return false;
      }

      if (status !== 0 && status !== 1) {
        return false;
      }

      return true;
    });

    if (!isValid) {
      setError("CSV data validation failed.");
    }
    return isValid;
  };

  // Mock CSV file processing (replace this with actual file reading logic if needed)
  const handleCategoryFileUpload = (data) => {
    const csvObject = [];
    const headers = ["Name", "Uuid", "Image", "Description", "ParentCategory", "Status"];
    const headersIncluded = headers.every((header) => data?.fields.includes(header));
    if (headersIncluded) {
      data?.data.forEach((e, i) => {
        const _name = e.Name ? String(e.Name).trim().replace(/\s+/g, " ") || null : null;
        const _image = e.Image ? String(e.Image).trim().replace(/\s+/g, " ") || null : null;
        const _description = e.Description ? String(e.Description).trim().replace(/\s+/g, " ") || null : null;
        const _uuid = e.Uuid ? String(e.Uuid).trim().replace(/\s+/g, " ") || null : null;
        const _parentCategory = e.ParentCategory ? String(e.ParentCategory).trim().replace(/\s+/g, " ") || null : null;
        const _status = e.Status;

        if (_name || _image || _description || _uuid) {
          const object = {
            id: i + 1,
            uuid: _uuid,
            image: _image,
            description: _description,
            status: _status,
            name: _name,
            parentCategory: _parentCategory,
          };
          csvObject.push(object);
        }
      });

      if (validateCSV(csvObject)) {
        setCategoryCsvList(csvObject);
        setModalSize("modal-xl");
      } else {
        setError("CSV validation failed.");
        setTimeout(() => setError(null), 4000);
      }
    } else {
      setError("CSV validation failed.");
      setTimeout(() => setError(null), 4000);
    }
  };

  const deleteCategory = (id) => {
    const updatedList = categoryCsvList.filter((category) => category.id !== id);
    setCategoryCsvList(updatedList);
    if (updatedList.length === 0) setModalSize("modal-md");
  };

  const handleSubmitCategoryCSV = async () => {
    setLoading((prev) => ({ ...prev, upload: true }));
    const payload = {
      data: categoryCsvList,
    };
    setCsvErrorList({});
    const response = await apiClient.post(`${API_URL}${UPLOAD_BULK_CATEGORY}`, payload);
    setCategoryCsvList([]);
    setCsvErrorList({ ...response?.data });
    setLoading((prev) => ({ ...prev, upload: false }));
  };

  const handleCategoryModalClose = () => {
    setError(null);
    setCategoryCsvList([]);
    setCsvErrorList({});
    setModalSize("modal-md");
    hideCsvModal();
  };

  return (
    <Modal centered className={`csvuploaddrr ${modalSize}`} show={showCSV} onHide={handleCategoryModalClose}>
      <Modal.Header className="modalheader text-center">
        Categories Upload CSV
        <span onClick={handleCategoryModalClose}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="modalcont mt-3">
          <div className="row">
            <div className="col-12">
              {categoryCsvList.length > 0 ? (
                <div className="csvfiledatalist">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Uuid</th>
                        <th>Parent Category</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryCsvList.map((category, index) => (
                        <tr key={category.id}>
                          <td>{index + 1}</td>
                          <td>
                            <span className="d-flex align-items-center">
                              {category.image ? (
                                <img className="avatartc" src={category.image} alt="" />
                              ) : (
                                <div className="avcharactr">
                                  {category.name
                                    ?.split(" ")
                                    .slice(0, 2)
                                    .map((word) => word[0].toUpperCase())
                                    .join("")}
                                </div>
                              )}
                              {category.name}
                            </span>
                          </td>
                          <td>{category.description}</td>
                          <td>{category.uuid}</td>
                          <td>{category.parentCategory}</td>
                          <td>{category.status === 1 ? "Active" : "Inactive"}</td>
                          <td>
                            <span className="cancelcsv" onClick={() => deleteCategory(category.id)}>
                              <HighlightOffOutlinedIcon />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="csvuploadefs">
                  <CSVUploadDropZone
                    onFileUpload={handleCategoryFileUpload}
                    text="Drag & drop or choose file to upload CSV"
                    accept=".csv"
                  />
                  <h6 className="mt-3 mb-0">Required Fields*</h6>
                  <p className="text-xs">
                    {`Name, Status (Status should be only 0 or 1)`} <br />
                    {`Uuid Should be unique.`}
                  </p>
                  {error && <p className="errormessage">{error}</p>}
                  <div className="d-flex justify-content-between align-item-center mt-3">
                    <p className="mb-0">
                      <a onClick={handleDownloadCSV} className="download-text" style={{ cursor: "pointer" }}>
                        <span>Download Category CSV Template</span>
                      </a>
                    </p>
                    <button onClick={handleCategoryModalClose} className="createbtn cancelbtn">
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>

            {categoryCsvList.length > 0 && (
              <div className="modal-footer-btn mt-3">
                <button className="createbtn cancelbtn" onClick={handleCategoryModalClose}>
                  Cancel
                </button>
                {loading.upload ? (
                  <button className="loading save">
                    <svg viewBox="25 25 50 50">
                      <circle r="20" cy="50" cx="50">
                        {""}
                      </circle>
                    </svg>
                  </button>
                ) : (
                  <button className="createbtn" onClick={handleSubmitCategoryCSV}>
                    Upload
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
