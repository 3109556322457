import moment from "moment";
import * as XLSX from "xlsx";

export const AUTH_TOKEN = "token";
export const BOM_REPORT_NAME = "BOM_REPORT";
export const CATEGORY_REPORT_NAME = "CATEGORY_REPORT";
export const PRODUCT_REPORT_NAME = "PRODUCT_REPORT";
export const LOGIN_REPORT_NAME = "LOGIN_REPORT";
export const DASHBOARD_REPORT_NAME = "DASHBOARD_REPORT";
export const getNameInitials = (str) => {
  if (typeof str !== "string") {
    return "";
  }
  let words = str.split(" ");
  let initials = words
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
  return initials;
};

export const validateString = (value, minLength, maxLength) => {
  if (!value) return false;
  if (typeof value !== "string") return false;
  if (value.length < minLength) return false;
  if (value.length > maxLength) return false;
  return true;
};

export const debounce = (func, timeout) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export const addChildrenRecursively = (categoryId, tree, children) => {
  if (!categoryId) {
    return children;
  }
  const treeClone = [...tree];
  return treeClone.map((item) => {
    if (item.key === categoryId) {
      item.children = children;
      item.leaf = children?.length === 0 ? true : false;
    } else if (item.children) {
      item.children = addChildrenRecursively(categoryId, item.children, children);
    }

    return item;
  });
};

export const getExpandedKeys = (list, keys = {}) => {
  list.forEach((item) => {
    if (item.children?.length > 0) {
      keys[item.key] = true;
      getExpandedKeys(item.children, keys);
    }
  });
  return keys;
};

export const makefilterQueryParams = (list = []) => {
  const queryParams = {};
  if (!Array.isArray(list)) {
    return queryParams;
  }
  if (list.length === 0) {
    return queryParams;
  }
  if (list.length > 0) {
    list.forEach((item) => {
      if (item.key in queryParams) {
        queryParams[item.key].push(item.value);
      } else {
        queryParams[item.key] = [item.value];
      }
    });
  }
  if ("active" in queryParams) {
    queryParams.active.length > 1 ? delete queryParams.active : (queryParams.active = queryParams.active[0]);
  }
  return queryParams;
};

export const getGroupIdList = (groupData, groupIdList = []) => {
  groupData?.map((item) => {
    groupIdList.push(item.uuid);
    if (item.children?.length > 0) {
      getGroupIdList(item.children, groupIdList);
    }
  });
  return groupIdList;
};

export const countNestedGroup = (list, count = 0) => {
  if (list?.children?.length > 0) {
    countNestedGroup(list.children, count);
  } else {
    count = count + list.length;
  }
  return count;
};

export const generateExcel = async (name, tableData) => {
  try {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(tableData);

    XLSX.utils.book_append_sheet(workbook, worksheet, `${name}`);

    XLSX.writeFile(workbook, `${name}_${moment().format("MMMM Do YYYY, h:mm:ss a")}.xlsx`);
  } catch (error) {
    console.log(error);
  } finally {
    return true;
  }
};

export const generateCombinedDataExcel = async (name, reportData = []) => {
  try {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(reportData[0]);

    if (reportData?.length > 1) {
      reportData.forEach((item, idx) => {
        if (idx !== 0) {
          XLSX.utils.sheet_add_json(worksheet, item, { origin: -1 });
        }
      });
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, `${name}`);

    XLSX.writeFile(workbook, `${name}_${moment().format("MMMM Do YYYY, h:mm:ss a")}.xlsx`);
  } catch (error) {
    console.log(error);
  } finally {
    return true;
  }
};

export const PLATFORM_TYPE = {
  MOBILE: "MOBILE",
  DESKTOP: "DESKTOP",
  DESKTOPVR: "DESKTOPVR",
  STANDALONEVR: "STANDALONEVR",
  UNIVERSAL: "UNIVERSAL",
  MACOS: "MACOS",
  IOS: "IOS",
};

export const saveFile = async (data, name) => {
  const blob = new Blob([data], { type: "text/csv" });
  const a = document.createElement("a");
  a.download = name;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export const savePdfFile = async (data, name) => {
  const blob = new Blob([data], { type: "application/pdf" });
  const a = document.createElement("a");
  a.download = name;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export const appendChildToCategoryTree = (tree, targetCategoryId, childData) => {
  if (tree.length === 0) {
    return [childData];
  }
  const newTree = tree.map((category) => {
    if (category.uuid === targetCategoryId) {
      category.children = [childData];
      return category;
    }
    if (category.children?.length > 0) {
      category.children = appendChildToCategoryTree(category.children, targetCategoryId, childData);
    }
    return category;
  });
  return newTree;
};

export const changePageNumber = (tree, targetCategoryId, pageNumber) => {
  return tree.map((category) => {
    if (category.uuid === targetCategoryId) {
      const newCategory = {...category, pageNumber: pageNumber};
      return newCategory;
    }
    if (category.children?.length > 0) {
      category.children = changePageNumber(category.children, targetCategoryId, pageNumber);
    }
    return category;
  });
};

const createMapping = (tree, mapping = {}) => {
  tree.forEach((category) => {
    mapping[category.uuid] = category.pageNumber || 1;
    if (category.children?.length > 0) {
      createMapping(category.children, mapping);
    }
  });
  return mapping;
};

const appendChildRecursively = (newTree, childData, level, treeLevel = 1) => {
  if (newTree.length === 0 && treeLevel === level) {
    return [childData];
  }

  return newTree.map((category) => {
    if (treeLevel === level) {
      category.children = [...(category.children || []), childData];
      return category;
    }
    category.children = appendChildRecursively(category.children, childData, level, treeLevel + 1);
    return category;
  });
};

export const createTreeFromBreadcrumb = (presentTree, newTree) => {
  const mapping = createMapping(presentTree);
  let categoryTree = [];

  newTree.forEach((category, idx) => {
    const childData = {
      uuid: category.uuid,
      pageNumber: mapping[category.uuid] || 1,
      children: [],
    };
    categoryTree = appendChildRecursively(categoryTree, childData, idx + 1);
  });

  return categoryTree;
};

export const findPageNumber = (tree, categoryId) => {
  let pageNumber;
  tree.forEach((item) => {
    if (item.uuid === categoryId) {
      pageNumber = item.pageNumber || 1;
    }
    if (item.children?.length > 0) {
      pageNumber = findPageNumber(item.children, categoryId);
    }
  });
  return pageNumber;
};
