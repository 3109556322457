import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { BackIcon, CancelIcon, EXLDownloadIcon, SearchesCIcon, SpinnerIcon } from "../Assets/Icons";
import apiClient from "../apiClient";
import { useSelector } from "react-redux";
import { FETCH_GROUP_HIERARCHY, FETCH_SESSION_LIST, FETCH_SESSION_REPORT } from "../apiClient/apiEndpoints";
import { generateExcel, getGroupIdList, LOGIN_REPORT_NAME } from "../utils/common";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import moment from "moment";

const SessionList = () => {
  const [slug, setSlug] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [sessionList, setSessionList] = useState({});
  const configData = useSelector((state) => state.config);
  const [reportLoading, setReportLoading] = useState(false);
  const [groupIdList, setGroupIdList] = useState([]);
  const API_URL = configData?.config?.API_URL;

  const fetchSessionList = async (groupList) => {
    const payload = {
      groupList,
      pageSize: configData.config?.DEFAULT_PAGE_SIZE || 10,
      pageNumber: page,
    };
    if (slug) {
      payload.query = slug;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_SESSION_LIST}`, payload);
    setSessionList(res);
  };

  const fetchGroupHierarchy = async () => {
    const res = await apiClient.get(`${API_URL}${FETCH_GROUP_HIERARCHY}/${groupId}`);
    const list = getGroupIdList([res] || []);
    setGroupIdList(list);
    return list;
  };

  const initializePage = async () => {
    setLoading(true);
    const groupList = await fetchGroupHierarchy();
    await fetchSessionList(groupList);
    setLoading(false);
  };

  const generateSessionReport = async () => {
    setReportLoading(true);
    const payload = {
      groupList: groupIdList,
    };
    const res = await apiClient.post(`${API_URL}${FETCH_SESSION_REPORT}`, payload);
    const reportData = res.map((item, idx) => ({
      "S.No.": idx + 1,
      "Group ID": item?.data?.groupCode,
      "Group Name": item?.data?.groupName,
      "Last Login": moment(item?.createdAt)?.format("DD MMM YYYY HH:mm") || "",
    }));
    generateExcel(LOGIN_REPORT_NAME, reportData);
    setReportLoading(false);
  };

  useEffect(() => {
    initializePage();
  }, [slug.trim(), page, groupId]);

  return (
    <div className="product-main sessionlist">
      <div className="col-md-6 d-flex align-items-center">
        <button className="backbtn cursor-pointer ps-2 mt-2" title="Back" onClick={() => navigate(-1)}>
          <BackIcon /> Back
        </button>
      </div>
      <div className="page-tile pb-3">
        <h5>Group Usage Activity</h5>
        <div className="searchnadd">
          <form className=" app-search app-search1 d-flex justify-content-start ">
            <div className="position-relative w-100">
              <SearchesCIcon />
              {slug && (
                <span
                  className="searchiconcancel"
                  onClick={() => {
                    setSlug("");
                  }}
                >
                  <CancelIcon />
                </span>
              )}
              <input
                type="text"
                name="searchName"
                className="form-control"
                placeholder="Search Sessions/Group"
                autoComplete="off"
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
              />
            </div>
          </form>
          <button
            className="DefaultRedBtn download px-2"
            title="Download Excel"
            onClick={generateSessionReport}
            disabled={reportLoading}
          >
            {reportLoading ? <SpinnerIcon /> : <EXLDownloadIcon />}
          </button>
        </div>
      </div>
      {loading ? (
        <>
          <div className="tablenpage">
            <div className="table-responsive cate-table p-0 ">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">S.No.</th>
                    <th className="text-center">Group ID</th>
                    <th>Group Name</th>
                    <th>Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(4)].map((_, index) => (
                    <tr key={index}>
                      <td>
                        <div className="table-text">
                          <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="tablenpage">
            <div className="table-responsive cate-table p-0 ">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">S.No.</th>
                    <th className="text-center">Group ID</th>
                    <th>Group Name</th>
                    <th>Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  {sessionList?.records?.map((session, index) => (
                    <tr key={session.uuid}>
                      <td className="text-center"> {index + sessionList.pagination.firstRecord} </td>
                      <td className="text-center"> {session?.data?.groupCode} </td>
                      <td> {session?.data?.groupName} </td>
                      <td> {moment(session?.createdAt).format("DD MMM YYY HH:mm")} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination">
            <div className="current-stat">
              {sessionList?.pagination?.firstRecord} - {sessionList?.pagination?.lastRecord} of{" "}
              {sessionList?.pagination?.totalRecords}
            </div>
            <div className="pages">
              <Pagination
                count={sessionList?.pagination?.totalPage}
                defaultPage={1}
                page={page}
                siblingCount={2}
                onChange={(e, pageNumber) => setPage(pageNumber)}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#333",
                    backgroundColor: "#f0f0f0",
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#ca373c",
                  },
                  "& .MuiPaginationItem-root:hover": {
                    backgroundColor: "#dcdcdc",
                  },
                }}
                boundaryCount={2}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SessionList;
